import React, { useState, useEffect, useMemo } from "react";
import { Routes, Route, Navigate, useSearchParams } from "react-router-dom";

import { ErrorBoundary } from "react-error-boundary";
import App from "./App";
import UnsubscribeEmail from "./Components/UnsubscribeEmail";
import sadNewsie from "./Assets/sad-newsie-192-removebg.png";
import ResetPassword from "./Components/ResetPassword";
import ReferralPage from './Components/ReferralPage';

import "./Router.css";
import LocalEdit from "./Components/LocalEdit";

const myErrorHandler = () => {
  localStorage.clear();
};

const ErrorComponent = () => {
  // Purge localStorage and reload... maybe tell users to reload instead??
  setTimeout(1000, () => window.location.reload());
  return (
    <div>
      <img src={sadNewsie} />
      <br />
      oops...
    </div>
  );
};

const Router = () => {
  const [rotatePhone, setRotatePhone] = useState(false);

  const [searchParams] = useSearchParams();

  const source = searchParams.get("refSource");
  const referralCode = searchParams.get("referralCode");

  if (source) localStorage.setItem("refSource", source);
  if (referralCode) localStorage.setItem("referralCode", referralCode);
  
  const resize = () => {
    if (window.matchMedia("(orientation: portrait)").matches) {
      setRotatePhone(false);
    }
    if (window.matchMedia("(orientation: landscape)").matches) {
      // console.log("orientation: landscape");
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        // true for mobile device
        setRotatePhone(true);
      }
    }
  };
  window.addEventListener("resize", resize);

  return (
    <ErrorBoundary onError={myErrorHandler} fallback={<ErrorComponent />}>
      {rotatePhone ? (
        <div className="landscape-layout">
          <img src={sadNewsie} />
          <div className="landscape-layout-text-container">
            Rotate Your Phone!
          </div>
        </div>
      ) : (
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/local" element={<LocalEdit />} />
          <Route path="/newsie/ref/:refCode" element={<ReferralPage />} />
          <Route path="/newsie/:date/:email/:emailOtpCode" element={<App />} />
          <Route path="/newsie/:date" element={<App />} />
          <Route
            path="/unsubscribe/:email/:emailToken"
            element={<UnsubscribeEmail />}
          />
          <Route
            path="/reset-password/:accountRecoveryToken"
            element={<ResetPassword />}
          />
          <Route
            path="/account/email/verify/:accountRecoveryToken"
            element={<App />}
          />
          <Route path="/plan" element={<Navigate to="/" />} />
        </Routes>
      )}
    </ErrorBoundary>
  );
};
export default Router;
